import React from "react"
import { navigate } from '@reach/router';
// import View from "./View"
import { useState} from "react"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { setUser, isLoggedIn } from "../utils/auth"
import { useFirebase } from "gatsby-plugin-firebase"

const Login = () => {
  const [firebase, setFirebase] = useState();

  useFirebase(firebase => {
    setFirebase(firebase);
  }, [])

  if (isLoggedIn()) {
    navigate(`/app/home`)
  }

  function getUiConfig(auth) {
    return {
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          providerName: 'InMoment',
          buttonColor: '#333',
          // iconUrl: '<icon-url-of-sign-in-button>',
          customParameters: {
            // Forces account selection even when one account
            // is available.
            hd: 'inmoment.com'
          }
        },
        {
          provider: 'microsoft.com',
          providerName: 'MeritzCX',
          buttonColor: '#FFF',
          // iconUrl: '<icon-url-of-sign-in-button>',
          loginHintKey: 'login_hint',
          customParameters: {
            tenant: 'fa094d07-6440-4f3b-bac6-7f996efff894',
            prompt: 'consent'
          }
        }
      ],
      // signInSuccessUrl: '/app/profile',
      callbacks: {
        signInSuccessWithAuthResult: (result) => {
          setUser(result.user);
          navigate('/app/home');
        }
      }
    };
  }

  return (
    <div className="login">
      {firebase && <StyledFirebaseAuth uiConfig={getUiConfig(firebase.auth)} firebaseAuth={firebase.auth()}/>}
    </div>
  );

}

export default Login
